import React, { useState, useEffect, useLayoutEffect } from "react";
import Onboard from "@web3-onboard/core";
import injectedModule from "@web3-onboard/injected-wallets";
import image from "./demo.png";
import base64 from "base-64";
import ReactDOM from "react-dom";
import NameNWallet from "./nameNWallet";
import NFTContract from "./contracts/NFTContract";
import Info from "./info";
// import Web3 from "web3";
import axios from "axios";
import About from "./about";
import WalletConnect from "@walletconnect/web3-provider";
import "./App.css";
import Contract from "web3-eth-contract";
import { ethers } from "ethers";
import Web3Modal from "web3modal";

const INFURA_ID = "9b2ce6abf15f4577a2f233789eda4239";

export const providerOptions = {
  walletconnect: {
    package: WalletConnect,
    options: {
      infuraId: "9b2ce6abf15f4577a2f233789eda4239",
    },
  },
};

//100 years DONE
//year - 4
//1,2,3 years - OG strikethrough
//1,2,3 years - OG
//mobile trustwallet DONE
//desketop wallet connect DONE

const contractAddress = Info.address;

document.body.style = "background: #EEEEEE";

const web3Modal = new Web3Modal({
  providerOptions, // required
});

const svgPlaceholder = `<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 350 350"><style>.base { fill: #C4C4C4; font-family: sans-serif; font-size: 14px; font-weight: 300;}</style><rect width="100%" height="100%" fill="white" /><text x="112" y="180" class="base">Select a year above</text></svg>`;

export default function App() {
  const [isFAQ, setIsFAQ] = useState(false);
  const [web33, setWeb33] = useState(null);
  const [canMint, setCanMint] = useState(false);
  const [transactionHash, setTransactionHash] = useState(null);
  const [mintedTokenId, setMintedTokenId] = useState("0");
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [isOG, setIsOG] = useState(false);
  const [OGnumber, setOGNumber] = useState(0);
  const [isConnected, setIsConnected] = useState(false);

  const connectWallet = async () => {
    try {
      if (!isConnected && canMint) {
        await web3Modal.clearCachedProvider();
        const provider = await web3Modal.connect();
        console.log(provider);
        const library = new ethers.providers.Web3Provider(provider);
        setProvider(provider);
        console.log(provider.selectedAddress);
        setLibrary(library);
        console.log(library.provider);
        setIsConnected(true);
      }
      if (isConnected && canMint) {
        Contract.setProvider(provider);

        var contract = new Contract(NFTContract, contractAddress);
        console.log(contract);
        console.log(provider.selectedAddress);

        contract.methods
          .mint(OGnumber, new Date().getFullYear())
          .send({
            from: provider.selectedAddress,
            gas: 200000,
            value: 10000000000000000,
          })
          .once("transactionHash", function (hash) {
            document.getElementById("buttonText").innerText = "Minting...";
            console.log("minting");
            setTransactionHash(hash);
          })
          .then(async function (result) {
            if (isOG) {
              document.getElementById("infoText").innerText =
                "Congrats, ur OG Badge NFT is minted!";
            } else {
              document.getElementById("infoText").innerText =
                "Congrats, ur NO OG Badge NFT is minted!";
            }
            document
              .getElementById("connectButton")
              .classList.remove("d-block");
            document.getElementById("connectButton").classList.add("d-none");
            document.getElementById("mintedlLinks").classList.add("d-block");
            document.getElementById("mintedlLinks").classList.remove("d-none");
            let generatedToken = result.events.Transfer.returnValues.tokenId;
            setMintedTokenId(generatedToken);
            console.log(generatedToken);
          })
          .catch((x) => {
            document.getElementById("buttonText").innerText = "Error";
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  function parentMethod() {
    setIsFAQ(false);
  }

  async function authenticate() {
    console.log("auth");
  }

  function about() {
    setIsFAQ(true);
  }

  function generateArt(yearValue) {
    if (yearValue == 1 || yearValue == 2 || yearValue == 3) {
      setIsOG(false);
      return `<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 350 350"><style>.base { fill: black; font-family: sans-serif; font-size: 32px; text-decoration: line-through }</style><rect width="100%" height="100%" fill="white" /><text x="10" y="35" class="base">OG</text></svg>`;
    }
    if (yearValue == 4) {
      setIsOG(true);
      return `<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 350 350"><style>.base { fill: black; font-family: sans-serif; font-size: 32px }</style><rect width="100%" height="100%" fill="white" /><text x="10" y="35" class="base">OG</text></svg>`;
    }
    setIsOG(true);
    let score = yearValue - 3;
    let OGString = "";
    let textYArray = [35, 65, 95, 125, 155, 185, 215, 245, 275, 305];
    let OGArray = ["", "", "", "", "", "", "", "", "", "", ""];
    let rowCounter = 0;
    rowCounter = Math.floor((score + 1) / 13);
    for (let i = 0; i < score; i++) {
      OGArray[Math.floor(i / 13)] = OGArray[Math.floor(i / 13)] + "O";
      if (i + 1 == score) {
        OGArray[Math.floor((i + 1) / 13)] =
          OGArray[Math.floor((i + 1) / 13)] + "G";
      }
    }

    let texts = "";

    for (let i = 0; i <= rowCounter; i++) {
      texts =
        texts +
        '<text x="10" y="' +
        textYArray[i] +
        '" class="base">' +
        OGArray[i] +
        "</text>";
    }

    let finalStrng =
      '<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 350 350"><style>.base { fill: black; font-family: sans-serif; font-size: 32px; }</style><rect width="100%" height="100%" fill="white" />';

    console.log(texts);
    OGString = OGString + "G";
    finalStrng = finalStrng + texts + `</svg>`;

    return finalStrng;
  }

  function choice() {
    let yearValue = parseInt(document.getElementById("select").value);
    if (yearValue == 0) {
      setCanMint(false);
      document.getElementById("art").src =
        "data:image/svg+xml;base64," + base64.encode(svgPlaceholder);
    } else {
      setCanMint(true);
      setOGNumber(yearValue);
      document.getElementById("art").src =
        "data:image/svg+xml;base64," + base64.encode(generateArt(yearValue));
    }
  }

  return (
    <div>
      <div className="container width-576">
        <div id="UI-div" className={isFAQ ? "d-none" : "d-block"}>
          <div className="pt-3 pb-3">
            <div>
              <div
                id="link"
                className="d-flex flex-row justify-content-between OGtext"
              >
                <p>OGenerator</p>
                <p onClick={about} className="d-block d-md-none">
                  ☞faq
                </p>
              </div>
            </div>
            <p className="OGtext" style={{ whiteSpace: "pre-wrap" }}>
              How many years have you been involved in art and technology?
            </p>
          </div>

          <select
            style={{ width: "225px" }}
            className="px-1 form-select OGtext"
            id="select"
            onChange={choice}
            aria-label="Default select example"
          >
            <option value="0" selected>
              X years
            </option>
            {}
            <option value="1">1 year</option>
            {Array.from(Array(100).keys())
              .slice(2)
              .map(function (item, i) {
                return <option value={item}>{item} years</option>;
              })}
          </select>
          <img
            width="3000px"
            id="art"
            className="img-fluid pt-3"
            src={
              "data:image/svg+xml;base64," +
              base64.encode(
                `<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 350 350"><style>.base { fill: #C4C4C4; font-family: sans-serif; font-size: 14px; font-weight: 300;}</style><rect width="100%" height="100%" fill="white" /><text x="112" y="180" class="base">Select a year above</text></svg>`
              )
            }
            alt=""
          />

          <p className="pt-2 OGtext" id="infoText">
            Mint OG Badge NFT: 0.01ETH
          </p>
          <div id="connectButton" className="d-block OGtext">
            <div
              className="d-inline-block mt-3"
              style={{
                height: "48px",
                paddingTop: "5px",
                textAlign: "center",
                background: canMint ? "white" : "#EEEEEE",
                border: "1px solid #000000",
              }}
            >
              <p
                onClick={connectWallet}
                id="buttonText"
                className="px-3 OGtext"
              >
                {isConnected ? "Mint" : "Connect Wallet"}
              </p>
            </div>
          </div>
          <div id="mintedlLinks" className="d-none mt-3">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://etherscan.io/tx/${transactionHash}`}
              className="pr-1"
            >
              <div
                className="d-inline-block"
                style={{
                  height: "48px",
                  paddingTop: "5px",
                  textAlign: "center",
                  background: "white",
                  border: "1px solid #000000",
                }}
              >
                <p className="px-3 OGtext">Etherscan</p>
              </div>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://zora.co/collections/${contractAddress.toLowerCase()}/${mintedTokenId}`}
              className="px-1 d-none"
            >
              <div
                className="d-inline-block"
                style={{
                  height: "48px",
                  paddingTop: "5px",
                  textAlign: "center",
                  background: "white",
                  border: "1px solid #000000",
                }}
              >
                <p className="px-3 OGtext">Zora</p>
              </div>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://opensea.io/assets/${contractAddress.toLowerCase()}/${mintedTokenId}`}
              className="px-1"
            >
              <div
                className="d-inline-block"
                style={{
                  height: "48px",
                  paddingTop: "5px",
                  textAlign: "center",
                  background: "white",
                  border: "1px solid #000000",
                }}
              >
                <p className="px-3 OGtext">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://opensea.io/assets/${contractAddress.toLowerCase()}/${mintedTokenId}`}
                    className="px-1 "
                  >
                    OpenSea
                  </a>
                </p>
              </div>
            </a>
          </div>
          <div id="mobileInfo" className="d-block d-md-none pb-3 OGtext">
            <p className="pt-4">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://etherscan.io/address/${contractAddress.toLowerCase()}`}
              >
                ☞contract
              </a>
            </p>

            <p className="pt-2 d-none">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://zora.co/collections/${contractAddress.toLowerCase()}`}
              >
                ☞zora
              </a>
            </p>
            <p className="pt-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://opensea.io/collection/ogenerator"
              >
                ☞opensea
              </a>
            </p>
            <p className="pt-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/Damjanski"
              >
                ☞twitter
              </a>
            </p>
          </div>
          <div className="d-none d-md-block">
            <div
              id="link"
              className="py-4 d-flex flex-row justify-content-between"
            >
              <p className="clickable OGtext" onClick={about}>
                ☞faq
              </p>
              <p className="OGtext">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://etherscan.io/address/${contractAddress.toLowerCase()}`}
                >
                  ☞contract
                </a>
              </p>
              <p className="OGtext d-none">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://zora.co/collections/${contractAddress.toLowerCase()}`}
                >
                  ☞zora
                </a>
              </p>
              <p className="OGtext">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://opensea.io/collection/ogenerator"
                >
                  ☞opensea
                </a>
              </p>
              <p className="OGtext">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/Damjanski"
                >
                  ☞twitter
                </a>
              </p>
            </div>
          </div>
        </div>
        <div id="About-div" className={isFAQ ? "d-block" : "d-none"}>
          <About parentMethod={() => parentMethod()}></About>
        </div>
      </div>
    </div>
  );
}

{
  /* <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 350 350"><style>.base { fill: black; font-family: sans-serif; font-size: 32px; }</style><rect width="100%" height="100%" fill="white" /><text x="10" y="35" class="base">OOOOOOOOOOOOG</text></svg> */
}
