import React from "react";
import Info from "./info";
const contractAddress = Info.address;

export default function about(props) {
  return (
    <div className="OGtext">
      <p
        onClick={() => props.parentMethod("Hello from child")}
        className="lh32 pt-3 clickable"
      >
        ☜
      </p>
      <p className="pt-3">What is the OGenerator?</p>
      <p className="pt-3">
        The "OGenerator" creates OG Badge NFTs that show how many years a person
        has been involved in art and technology straight from their wallet.
        Goodbye weird Twitter bio OG descriptions. Each NFT is stored and fully
        rendered on chain, making sure that information never gets lost.
      </p>
      <p className="pt-3">How does it work?</p>
      <p className="pt-3">
        Just select the year in the dropdown menu when you started getting
        involved. This information will impact the visual representation of the
        NFT. Afterwards, you can press mint to confirm the transaction in your
        wallet, to start the process of minting your OG NFT Badge.
      </p>
      <p className="pt-3">What is the Formula behind the OGenerator?</p>
      <p className="pt-3" style={{ whiteSpace: "pre-line" }}>
        People start calling themselves OGs when they've been around in 2018.
        That's where the OG status starts. There will be an additional "O" for
        each year prior to 2018. And moving forward each year, the OG Badge NFT
        will add another "O" to your OG Badge. Plus in the future the entry
        level for OG will always be the current year minus three years. Formula:
        {"\n"}(Current Year) - ((Year OG Badge Minted) – (Number of Years
        Involved)) - 3 = (Amount of “O”)G
      </p>
      <p className="pt-3">What is the price of an OG NFT Badge?</p>
      <p className="pt-3">The cost to mint is 0.01 ETH.</p>
      <p className="pt-3">More about the artist?</p>
      <p className="pt-3" style={{ whiteSpace: "pre-line" }}>
        Damjanski is an artist living in a browser. Concerned with themes of
        power, poetry and participation, he explores the concept of apps as
        artworks. The app "Bye Bye Camera" is the camera for the post-human era.
        Every picture people take automatically removes any person. The app
        "Computer Goggles" let’s people capture the world like a machine sees it
        and the "LongARcat" app creates long cats in AR.{"\n"}
        In 2018, he co-founded "MoMAR", an Augmented Reality gallery app aimed
        at democratizing physical exhibition spaces, art institutions and
        curatorial processes within New York’s Museum of Modern Art. WIRED
        covered the launch with the headline "Augmented Reality Is Transforming
        Museums".{"\n"}
        He created an online space that only programs can access. This software
        performance ,called "Humans not invited", first hit Reddit’s front page
        before it was shown at the König Galerie in Berlin.{"\n"}
        In March 2022, he published his first decentralized app{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://unhuman.xyz">
          ☞"Unhuman Compositions"
        </a>
        . It’s a collection of 777 participatory generative photography NFTs –
        each generated when a person takes a photo with the camera of their
        smartphone. The work invites people to explore the abstraction of our
        physical world through a generative algorithm.
        {"\n"}
        His work has appeared internationally, including exhibitions at
        NRW-Forum, König Galerie, Roehrs & Boetsch, Pioneer Works, MoCDA,
        Tropez, Import Projects. Currently Damjanski resides in New York. More
        information{" "}
      </p>
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.damjanski.com/"
        >
          ☞www.damjanski
        </a>
      </p>
      <p className="pt-4">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://etherscan.io/address/${contractAddress.toLowerCase()}`}
        >
          ☞contract
        </a>
      </p>
      <p className="pt-4">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/Damjanski"
        >
          ☞twitter
        </a>
      </p>
      <p className="pt-4 d-none">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://zora.co/collections/${contractAddress.toLowerCase()}`}
        >
          ☞zora
        </a>
      </p>
      <p className="pt-4">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://opensea.io/collection/ogenerator"
        >
          ☞opensea
        </a>
      </p>
      <p
        onClick={() => props.parentMethod("Hello from child")}
        className="lh32 py-5 clickable"
      >
        ☜
      </p>
    </div>
  );
}
